<template>
    <div class="page">
            <div class="box">
                <h2 >{{detailObj.title}}</h2>
                <ul>
                    <li v-if="detailObj.author||detailObj.source">作者：{{detailObj.author?detailObj.author:(detailObj.source?detailObj.source:'来源')}}   </li>
                    <li v-if="detailObj.releaseTime">    发布时间：{{detailObj.releaseTime}}</li>
                    <li v-if="detailObj.lookNum">浏览量：{{detailObj.lookNum}}</li>
                </ul>
                <el-table v-if="type==2" :data="detailObj.list" :span-method="objectSpanMethod" border style="width: 100%; margin-top: 20px">
                    <el-table-column prop="work" label="星期" align="center" width="180"></el-table-column>
                    <el-table-column prop="time" label="时间" align="center">
                        <template slot-scope="scope">
                            <div v-for="(val,index) in scope.row.time" :key="index">
                                <div>{{val+'\n'}}</div>
                              </div>
                        </template>
</el-table-column>
<el-table-column prop="details" label="内容" align="center">
    <template slot-scope="scope">
        <div v-for="(val,index) in scope.row.details" :key="index">
            <div>{{val+'\n'}}</div>
          </div>
    </template>
</el-table-column>
<el-table-column prop="place" label="地点" align="center">
    <template slot-scope="scope">
        <div v-for="(val,index) in scope.row.place" :key="index">
            <div>{{val+'\n'}}</div>
          </div>
    </template>
</el-table-column>
<el-table-column prop="deptName" label="负责人/部门" align="center">
    <template slot-scope="scope">
        <div v-for="(val,index) in scope.row.deptName" :key="index">
            <div>{{val+'\n'}}</div>
          </div>
    </template>
</el-table-column>
</el-table>
<el-table v-if="type==3" :data="detailObj.list" border style="width: 100%; margin-top: 20px">
    <el-table-column prop="week" label="星期" align="center"></el-table-column>
    <el-table-column prop="name" label="早餐食谱" align="center"> </el-table-column>
    <el-table-column prop="food" label="午餐食谱" align="center"> </el-table-column>
    <el-table-column prop="soup" label="午点" align="center"> </el-table-column>
</el-table>
</div>
</div>
</template>
<script>
    import {
        noticeDetail,
    } from "@/request/api";
    export default {
        props: ['conList', 'tableList', 'type'],
        data() {
            return {
                detailTitle: '',
                detailObj: {},
                spanArr: [],
                type: 3,
            }
        },
        created() {
            let id = this.$route.query.id;
            this.type = this.$route.query.type;
            this.noticeDetail(id, this.type);
        },
        methods: {
            noticeDetail(id, type) {
                const _this = this;
                _this.pageShow = false;
                let data = {
                    id: id,
                    type: type
                }
                noticeDetail(data).then(res => {
                    _this.detailObj = res.data
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },

        }
    }
</script>
<style scoped lang="scss">
    .box {
        width: 98%;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin: auto;
        h2 {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            text-align: center;
            margin-bottom: 10px;
        }
        ul {
            display: flex;
            justify-content: center;
            li {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 8px;
            }
            li:last-child {
                margin-right: 0;
            }
        }
        p {
            margin: 20px auto 0;
        }
    }
    
    .box p ::v-deep table {
        margin: 10px auto 0;
    }
    
    .detail-img img {
        display: block;
        margin: auto;
    }
    
    @media screen and (max-width: 750px) {
        .box {
            width: 100%;
            h2 {
                font-size: .46rem;
            }
        }
    }
</style>